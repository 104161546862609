import { useState } from "react"
import { Container } from "reactstrap"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

import ProjectTDPList from "./ProjectTDPList"
import ProjectPCBList from "./ProjectPCBList"
import ProjectLCList from "./ProjectLCList"

const ProjectPanel = ({ type }) => {
  const [selectedTab, setSelectedTab] = useState(0)

  const handleTabChange = (index) => {
    setSelectedTab(index)
  }

  return (
    <Container>
      <Tabs selectedIndex={selectedTab} onSelect={handleTabChange}>
        <div className="title-text">
          <h2>
            {type === "TDP"
              ? "3D Print Queue"
              : type === "PCB"
              ? "PCB Mill Queue"
              : "Laser Cut Queue"}
          </h2>
        </div>
        <TabList>
          <Tab>Unfulfilled</Tab>
          <Tab>In Progress</Tab>
          <Tab>Completed</Tab>
          <Tab>Archived</Tab>
          <Tab>Canceled</Tab>
        </TabList>

        <TabPanel>
          {type === "TDP" && <ProjectTDPList statusFilter={"UN"} />}
          {type === "PCB" && <ProjectPCBList statusFilter={"UN"} />}
          {type === "LC" && <ProjectLCList statusFilter={"UN"} />}
        </TabPanel>

        <TabPanel>
          {type === "TDP" && <ProjectTDPList statusFilter={"IP"} />}
          {type === "PCB" && <ProjectPCBList statusFilter={"IP"} />}
          {type === "LC" && <ProjectLCList statusFilter={"IP"} />}
        </TabPanel>

        <TabPanel>
          {type === "TDP" && <ProjectTDPList statusFilter={"CP"} />}
          {type === "PCB" && <ProjectPCBList statusFilter={"CP"} />}
          {type === "LC" && <ProjectLCList statusFilter={"CP"} />}
        </TabPanel>

        <TabPanel>
          {type === "TDP" && <ProjectTDPList statusFilter={"AR"} />}
          {type === "PCB" && <ProjectPCBList statusFilter={"AR"} />}
          {type === "LC" && <ProjectLCList statusFilter={"AR"} />}
        </TabPanel>

        <TabPanel>
          {type === "TDP" && <ProjectTDPList statusFilter={"CN"} />}
          {type === "PCB" && <ProjectPCBList statusFilter={"CN"} />}
          {type === "LC" && <ProjectLCList statusFilter={"CN"} />}
        </TabPanel>
      </Tabs>
    </Container>
  )
}

export default ProjectPanel
