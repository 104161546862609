import React, { useState, useEffect } from "react"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Alert,
  Row,
  Col,
} from "reactstrap"
import { PROJECT_PCB_URL } from "../../constants"

import api from "../../api"

const NewProjectPCBForm = ({ project, toggle, resetState, create }) => {
  const STATUS_CHOICES = [
    ["UN", "Unfulfilled"],
    ["IP", "In Progress"],
    ["CP", "Completed"],
    ["AR", "Archived"],
    ["CA", "Cancelled"],
  ]

  const PURPOSE_CHOICES = [
    ["PE", "Personal"],
    ["CL", "Class"],
    ["RE", "Research"],
  ]

  const [formData, setFormData] = useState({
    pk: 0,
    status: "UN",
    purpose: "",
    customerName: "",
    customerEmail: "",
    date: new Date().toISOString().slice(0, 10),
    quantity: 0,
    doubleSided: false,
    silkscreen: false,
    comments: "",
    file: null,
    boardArea: 0,
  })
  const [errorMessage, setErrorMessage] = useState("")
  const [newFileSelected, setNewFileSelected] = useState(false)

  useEffect(() => {
    if (project) {
      console.log(project)
      setFormData({
        pk: project.pk,
        status: project.status,
        purpose: project.purpose,
        customerName: project.customerName,
        customerEmail: project.customerEmail,
        date: project.date,
        quantity: project.quantity,
        doubleSided: project.doubleSided,
        silkscreen: project.silkscreen,
        comments: project.comments,
        file: project.file,
        boardArea: project.boardArea,
      })
    }
  }, [])

  const onChange = (e) => {
    if (e.target.name === "file") {
      setFormData({ ...formData, file: e.target.files[0] })
      setNewFileSelected(true)
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value })
    }
  }

  const sendReceivedEmail = async () => {
    try {
      const data = { email: formData.customerEmail, projectType: "PCB Milling" }

      const res = await api.post(process.env.REACT_APP_PROJECT_RECEIVED, data)
    } catch (err) {
      console.error("Failed to send email", err)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    if (
      !formData.customerName ||
      !formData.customerEmail ||
      !formData.file ||
      !formData.quantity
    ) {
      setErrorMessage("Please fill out all fields.")
      return
    }

    const data = new FormData()
    Object.keys(formData).forEach((key) => {
      if (formData[key] !== null && (key !== "file" || newFileSelected)) {
        data.append(key, formData[key])
      }
    })

    const request = project
      ? api.put(`${PROJECT_PCB_URL}${formData.pk}`, data)
      : api.post(PROJECT_PCB_URL, data)

    request
      .then(() => {
        sendReceivedEmail()
        toggle()
        resetState()
      })
      .catch((error) => {
        console.error(
          `Error ${project ? "editing" : "creating"} request: `,
          error
        )
        setErrorMessage(
          `Error ${project ? "editing" : "creating"} request. Please try again.`
        )
      })
  }

  const defaultIfEmpty = (value) => (value === "" ? "" : value)

  return (
    <Form onSubmit={handleSubmit}>
      {errorMessage && <Alert color="danger">{errorMessage}</Alert>}

      <h5>Request Information:</h5>
      <Row>
        <Col>
          <FormGroup>
            <Label for="customerName">Name:</Label>
            <Input
              type="text"
              name="customerName"
              onChange={onChange}
              value={defaultIfEmpty(formData.customerName)}
              disabled={!create}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="customerEmail">Email:</Label>
            <Input
              type="text"
              name="customerEmail"
              onChange={onChange}
              value={defaultIfEmpty(formData.customerEmail)}
              disabled={!create}
            />
          </FormGroup>
        </Col>
      </Row>

      <h5 className="mt-4">Gerber Files:</h5>
      {create ? (
        <FormGroup>
          <Label htmlFor="file">
            Submit a .zip containing your Gerber files
            <p style={{ fontWeight: "bold" }}>
              PLEASE HAVE YOUR FILE SETTINGS MATCH&nbsp;
              <a
                href="https://github.com/BYU-elc/pcbdesign"
                target="_blank"
                rel="noopener noreferrer"
              >
                THIS FORMAT
              </a>
            </p>
          </Label>
          <Input
            type="file"
            name="file"
            onChange={onChange}
            disabled={!create}
          />
        </FormGroup>
      ) : (
        <Row>
          <Col>
            <strong>Project File: </strong>
            {project.file_name}
          </Col>
          <Col>
            <Button href={project.file} download>
              Download
            </Button>
          </Col>
        </Row>
      )}

      <strong>
        A Singled Sided Board costs $0.40 per square inch. A Double Sided costs
        $0.80 per square inch
      </strong>

      <h5 className="mt-5">Board Settings:</h5>
      <Row>
        <Col>
          <FormGroup>
            <Label for="quantity">Board Quantity:</Label>
            <Input
              className="w-25"
              type="number"
              name="quantity"
              onChange={onChange}
              value={formData.quantity}
            />
          </FormGroup>
          <FormGroup>
            <Label for="boardArea">Board Area (Sq Inches):</Label>
            <Input
              className="w-25"
              type="number"
              name="boardArea"
              onChange={onChange}
              value={formData.boardArea}
            />
          </FormGroup>
        </Col>
        <Col>
          {/* {If the board is double sided, it cannot be silked screen. 
          The following logic makes sure silk screen is set to disabled 
          depending on what double sided is} */}
          <FormGroup>
            <Label for="boardArea">PCB Siding:</Label>
            <Input
              type="select"
              name="doubleSided"
              onChange={(e) => {
                const selectedValue = e.target.value
                setFormData({
                  ...formData,
                  doubleSided: selectedValue === "Double Sided",
                  silkscreen:
                    selectedValue === "Doubled Sided"
                      ? false
                      : formData.silkscreen,
                })
              }}
            >
              {project ? (
                <option>
                  {project.doubleSided ? "Double Sided" : "Single Sided"}
                </option>
              ) : (
                <>
                  <option>Single Sided</option>
                  <option>Double Sided</option>
                </>
              )}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="silkscreen">Silkscreen (only for Single Sided)</Label>
            <Input
              type="select"
              name="silkscreen"
              disabled={formData.doubleSided}
              onChange={(e) => {
                const selectedValue = e.target.value
                setFormData({
                  ...formData,
                  silkscreen: selectedValue === "Yes",
                })
              }}
            >
              {!create ? (
                <option>
                  {formData.silkscreen && !formData.doubleSided ? "Yes" : "No"}
                </option>
              ) : formData.doubleSided ? (
                <option>No</option>
              ) : (
                <>
                  <option>No</option>
                  <option>Yes</option>
                </>
              )}
            </Input>
          </FormGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label for="comments">Additional comments:</Label>
        <Input
          name="comments"
          type="textarea"
          placeholder="If you need rubout, or this is an RF board, you MUST specify here, or we will not process any rubout areas/use RF applications."
          onChange={onChange}
          value={defaultIfEmpty(formData.comments)}
        />
      </FormGroup>

      <Button>Send</Button>
    </Form>
  )
}

export default NewProjectPCBForm
