import React, { useState, Fragment, useRef } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Form,
  Input,
  Label,
} from "reactstrap"

import api from "../../api"

const EditQuantity = ({ resetState, item }) => {
  const [modal, setModal] = useState(false)
  const [quantityToOrder, setQuantityToOrder] = useState(item.quantity)
  const inputRef = useRef(null)

  // Reflect user interaction with state changes
  const onChange = (e) => {
    setQuantityToOrder(e.target.value)
  }

  // Change whether the frame is viewable or not
  const toggle = () => {
    setModal(!modal)
    setQuantityToOrder(item.quantity)
  }

  const editCartItemQunatity = async (e) => {
    // e.preventDefault() // Prevent form submission

    const payLoad = {
      pk: item.pk,
      quantity: quantityToOrder,
      name: item.name,
      price: item.price,
      cart: item.cart,
    }
    try {
      const response = await api.put(
        `${process.env.REACT_APP_CART_PUT}${item.pk}`,
        payLoad
      )
      resetState()
    } catch (error) {
      console.error("Error during test request:", error)
    }
  }

  const handleSubmit = (event) => {
    if (event.key === "Enter") {
      event.preventDefault()
      editCartItemQunatity()
      toggle()
    }
  }

  return (
    <Fragment>
      <Button color="primary" onClick={toggle} className="m-4">
        Edit Quantity
      </Button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        onOpened={() => inputRef.current?.focus()}
      >
        <ModalHeader toggle={toggle}>Enter item quantity</ModalHeader>
        <ModalBody>
          <Form onSubmit={editCartItemQunatity}>
            <FormGroup>
              <Label for="quantityToOrder">Quantity:</Label>
              <Input
                type="number"
                name="quantityToOrder"
                onChange={onChange}
                value={quantityToOrder}
                innerRef={inputRef}
                onKeyDown={handleSubmit}
              />
            </FormGroup>

            <Button
              onClick={() => {
                editCartItemQunatity()
                toggle()
              }}
            >
              Submit
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  )
}

export default EditQuantity
