import React, { useContext, useEffect, useState } from "react"
import "./Header.css"
import { AuthContext } from "../DAO/AuthContext"

import { useNavigate } from "react-router-dom"

import { Button } from "reactstrap"

const Header = () => {
  const { logout, isAuthenticated } = useContext(AuthContext)
  const [loggedin, setLoggedin] = useState(isAuthenticated)

  //updates loggedin variable when AuthContext is updated
  useEffect(() => {
    setLoggedin(isAuthenticated)
  }, [isAuthenticated])

  const navigate = useNavigate()

  const handleLogin = () => {
    window.location.href = "/accounts/login/"
  }

  const handleLogout = async () => {
    window.location.href = "/accounts/logout/"
  }

  const redirectToHome = () => {
    window.location.href = "/"
  }

  //render() {
  return (
    <header className="byu-header">
      <div className="byu-official">
        <div className="byu-logo">
          {/* This is the image on the left of the header */}
          <img
            src={`${process.env.PUBLIC_URL}/static/images/BYU_LOGO.png`}
            alt="BYU Logo"
            onClick={redirectToHome}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="byu-separator"></div> {/* White separator bar */}
        <div className="byu-title">
          {/* This is the title text */}
          <h2>ECE Shop</h2>
        </div>
      </div>
      <div className="login-logout-button">
        {loggedin ? (
          <Button onClick={handleLogout} color="light">
            Logout
          </Button>
        ) : (
          <Button onClick={handleLogin} color="light">
            Employee Login
          </Button>
        )}
      </div>
    </header>
  )
}

export default Header
