import React, { useState } from "react"
import { Button, Form, FormGroup, Input, Label } from "reactstrap"
import "react-datetime/css/react-datetime.css"
import { DayPicker } from "react-day-picker"

import api from "../../api"
import { LOANCART_ITEM_URL } from "../../constants"

const LoanForm = ({ item, isEquipment, resetState, toggle }) => {
  const [quantityToOrder, setQuantityToOrder] = useState(1)
  const [date, setDate] = useState()

  const currentYear = new Date().getFullYear()
  const startMonth = new Date(currentYear, 0)
  const endMonth = new Date(currentYear + 10, 11)

  const handleQuantityChange = (e) => {
    setQuantityToOrder(e.target.value)
  }

  const formatDate = (date) => {
    return date ? date.toISOString().split("T")[0] : ""
  }

  const createLoanItem = async (e) => {
    e.preventDefault()

    const data = {
      loanCart: 1,
      item: isEquipment ? null : item.pk,
      equipment: isEquipment ? item.pk : null,
      returnDate: formatDate(date),
      quantity: quantityToOrder,
      isEquipment: isEquipment,
    }

    try {
      await api.post(LOANCART_ITEM_URL, data)
      resetState()
      toggle()
      setQuantityToOrder(1)
      setDate()
    } catch (error) {
      console.error("Error creating loan item:", error)
    }
  }

  return (
    <div>
      <Form onSubmit={createLoanItem}>
        <FormGroup>
          <Label for="quantityToOrder">Quantity:</Label>
          <Input
            type="number"
            name="quantityToOrder"
            onChange={handleQuantityChange}
            value={quantityToOrder}
          />
        </FormGroup>
        <DayPicker
          className="day-picker"
          mode="single"
          selected={date}
          onSelect={setDate}
          max={1}
          hideNavigation
          captionLayout="dropdown"
          startMonth={startMonth}
          endMonth={endMonth}
        />

        <Button type="submit">Submit</Button>
      </Form>
    </div>
  )
}

export default LoanForm
