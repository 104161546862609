// Updated to Modern React 08/01/2024

import React, { useState, useEffect } from "react"
import { Button, Form, FormGroup, Input, Label, Alert } from "reactstrap"
import axios from "axios" // Axios is used for HTTP interactions. In this file, POST and PUT.
import { EQUIPMENT_URL } from "../../constants"

import api from "../../api"

const EquipmentForm = ({ equipment, resetState, toggle }) => {
  const [formData, setFormData] = useState({
    pk: 0,
    icn: 0,
    name: "",
    manufacturer: "",
    model: "",
    serialNumber: 0,
    price: 0,
    location: "",
    acquiredDate: "",
    lastSeenDate: "",
    lastSeenLocation: "",
    comments: "",
    errorMessage: "",
  })

  useEffect(() => {
    if (equipment) {
      const {
        pk,
        icn,
        name,
        manufacturer,
        model,
        serialNumber,
        price,
        location,
        acquiredDate,
        lastSeenDate,
        lastSeenLocation,
        comments,
      } = equipment
      setFormData({
        pk,
        icn,
        name,
        manufacturer,
        model,
        serialNumber,
        price,
        location,
        acquiredDate,
        lastSeenDate,
        lastSeenLocation,
        comments,
        errorMessage: "",
      })
    }
  }, [equipment])

  const handleChange = (e) => {
    const { name, type, value } = e.target
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "number" ? Number(value) : value,
    }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const {
      pk,
      name,
      manufacturer,
      model,
      serialNumber,
      price,
      location,
      acquiredDate,
      lastSeenDate,
      lastSeenLocation,
      comments,
    } = formData

    if (
      !name ||
      !manufacturer ||
      !model ||
      !serialNumber ||
      !price ||
      !location
    ) {
      setFormData((prevState) => ({
        ...prevState,
        errorMessage: "Please fill out all required fields.",
      }))
      return
    }

    try {
      if (pk) {
        await api.put(`${EQUIPMENT_URL}${pk}`, formData)
      } else {
        await api.post(`${EQUIPMENT_URL}create/`, formData)
      }
      resetState()
      toggle()
    } catch (error) {
      console.error("Error submitting form:", error)
      setFormData((prevState) => ({
        ...prevState,
        errorMessage: `Error ${
          pk ? "editing" : "creating"
        } equipment. Please try again.`,
      }))
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      {formData.errorMessage && (
        <Alert color="danger">{formData.errorMessage}</Alert>
      )}
      <FormGroup>
        <Label for="icn">ICN:</Label>
        <Input
          type="text"
          name="icn"
          onChange={handleChange}
          value={formData.icn || ""}
        />
      </FormGroup>
      <FormGroup>
        <Label for="name">Name:</Label>
        <Input
          type="text"
          name="name"
          onChange={handleChange}
          value={formData.name || ""}
        />
      </FormGroup>
      <FormGroup>
        <Label for="manufacturer">Manufacturer:</Label>
        <Input
          type="text"
          name="manufacturer"
          onChange={handleChange}
          value={formData.manufacturer || ""}
        />
      </FormGroup>
      <FormGroup>
        <Label for="model">Model:</Label>
        <Input
          type="text"
          name="model"
          onChange={handleChange}
          value={formData.model || ""}
        />
      </FormGroup>
      <FormGroup>
        <Label for="serialNumber">Serial Number:</Label>
        <Input
          type="text"
          name="serialNumber"
          onChange={handleChange}
          value={formData.serialNumber || ""}
        />
      </FormGroup>
      <FormGroup>
        <Label for="price">Price:</Label>
        <Input
          type="number"
          name="price"
          onChange={handleChange}
          value={formData.price || ""}
        />
      </FormGroup>
      <FormGroup>
        <Label for="location">Location:</Label>
        <Input
          type="text"
          name="location"
          onChange={handleChange}
          value={formData.location || ""}
        />
      </FormGroup>
      <FormGroup>
        <Label for="acquiredDate">Acquired Date:</Label>
        <Input
          type="date"
          name="acquiredDate"
          onChange={handleChange}
          value={formData.acquiredDate || ""}
        />
      </FormGroup>
      <FormGroup>
        <Label for="lastSeenDate">Last Seen Date:</Label>
        <Input
          type="date"
          name="lastSeenDate"
          onChange={handleChange}
          value={formData.lastSeenDate || ""}
        />
      </FormGroup>
      <FormGroup>
        <Label for="lastSeenLocation">Last Seen Location:</Label>
        <Input
          type="text"
          name="lastSeenLocation"
          onChange={handleChange}
          value={formData.lastSeenLocation || ""}
        />
      </FormGroup>
      <FormGroup>
        <Label for="comments">Comments:</Label>
        <Input
          type="text"
          name="comments"
          onChange={handleChange}
          value={formData.comments || ""}
        />
      </FormGroup>
      <Button type="submit">Send</Button>
    </Form>
  )
}

export default EquipmentForm
