import React, { useState, useRef } from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import api from "../../api"

const InProgressModal = ({ project, resetState, projectType }) => {
  const [modal, setModal] = useState(false)
  const [total, setTotal] = useState(
    projectType == "pcb" ? project.boardArea : null
  )
  const inputRef = useRef(null)

  const toggle = () => {
    setModal(!modal)
  }

  const sendCompletedEmail = async () => {
    try {
      let projectTypeName = ""

      // Map project type to corresponding string
      if (projectType === "tdp") {
        projectTypeName = "3D Print"
      } else if (projectType === "pcb") {
        projectTypeName = "PCB Milling"
      } else if (projectType === "lc") {
        projectTypeName = "Laser Cut"
      }

      const data = {
        email: project.customerEmail,
        projectType: projectTypeName,
      }

      const res = await api.post(process.env.REACT_APP_PROJECT_COMPLETED, data)
    } catch (err) {
      console.error("Failed to send email", err)
    }
  }

  const completeJob = async () => {
    const body = {
      status_type: "CP",
      total: total,
    }

    try {
      await api.post(
        `${process.env.REACT_APP_PROJECT_URL}${projectType}/update_job/${project.pk}`,
        body, // Body of the request
        {
          headers: {
            "Content-Type": "application/json", // Ensure you're sending JSON data
          },
        }
      )
      sendCompletedEmail()
      toggle() // Close the modal
      resetState() // Refresh the list of items
    } catch (error) {
      console.error("Error starting job:", error)
    }
  }

  const onChange = (e) => {
    e.preventDefault()
    setTotal(e.target.value)
  }

  const handleSubmit = (event) => {
    if (event.key === "Enter") {
      completeJob()
      toggle()
    }
  }

  return (
    <div>
      <Button onClick={toggle} className="m-4" color="success">
        Complete
      </Button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        onOpened={() => inputRef.current?.focus()}
      >
        <ModalHeader toggle={toggle}>
          Complete project for {project.customerName}
        </ModalHeader>

        <ModalBody>
          <FormGroup>
            <Label>
              {projectType == "pcb"
                ? "Board Area (Sq Inces)"
                : projectType == "tdp"
                ? "Total Grams"
                : "Price:"}
            </Label>
            <Input
              className="w-25"
              type="number"
              name="total"
              onChange={onChange}
              value={total}
              innerRef={inputRef}
              onKeyDown={handleSubmit}
            />
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <Button type="button" onClick={toggle}>
            Close
          </Button>
          <Button type="button" color="primary" onClick={completeJob}>
            Complete Job
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default InProgressModal
