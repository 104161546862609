import React, { useState } from "react"
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Col,
} from "reactstrap"
import api from "../../api"

const StartJobModal = ({ typeOfRequest, project, resetState, projectType }) => {
  const [modal, setModal] = useState(false)

  const toggle = () => {
    setModal(!modal)
  }

  const startJob = async () => {
    try {
      await api.post(
        `${process.env.REACT_APP_PROJECT_URL}${projectType}/update_job/${project.pk}`,
        { status_type: "IP" }
      )
      toggle()
      resetState()
    } catch (error) {
      console.error("Error starting job:", error)
    }
  }

  return (
    <>
      <Button onClick={toggle} className="m-4" color="warning">
        Start Job
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <Row>
            {typeOfRequest} Request: {project.customerName}
          </Row>
          <Row>
            <p>
              <a href={`mailto:${project.customerEmail}`}>
                {project.customerEmail}
              </a>
            </p>
          </Row>
        </ModalHeader>

        <ModalBody>
          <Row>
            <Col>
              <strong>Project File: </strong>
              {project.file_name}
            </Col>
            <Col>
              <Button href={project.file} download>
                Download
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>Comments:</strong> {project.comments}
            </Col>
          </Row>
          {projectType == "tdp" && (
            <>
              <Row>
                <Col>
                  <strong>Print Quantity:</strong> {project.quantity}
                </Col>
              </Row>
              <Row>
                <Col>
                  <strong>Infill:</strong> {project.infill}
                </Col>
              </Row>
              <Row>
                <Col>
                  <strong>Color:</strong> {project.color1}
                </Col>
              </Row>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <Button type="button" onClick={toggle}>
            Close
          </Button>
          <Button type="button" color="primary" onClick={startJob}>
            Start Job
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default StartJobModal
