//Created by Logan Cropper 8/9/24

import React, { useState, useEffect, Fragment, useContext } from "react"
import { Col, Container, Row } from "reactstrap"

import { PROJECT_TDP_URL } from "../../constants"
import Table from "../Table/Table"
import { createColumnHelper } from "@tanstack/react-table"
import { AuthContext } from "../DAO/AuthContext"
import NewProjectTDPModal from "./NewProjectTDPModal"
import axios from "axios"

import StartJobModal from "./StartJobModal"
import InProgressModal from "./InProgressModal" // Import different modals
import CompleteModal from "./CompleteModal"
import EditStatusButton from "./EditStatusButton"
import MoveToCancelledModal from "./MoveToCancelledModal"

const ProjectTDPList = ({ statusFilter }) => {
  const columnHelper = createColumnHelper()
  const [projects, setProjects] = useState([])
  const { isAuthenticated } = useContext(AuthContext)

  const getModalComponent = (status, row) => {
    switch (status) {
      case "UN":
        return (
          <StartJobModal
            project={row?.original}
            type={"PCB Mill"}
            resetState={resetState}
            projectType={"tdp"}
          />
        )
      case "IP":
        return (
          <InProgressModal
            project={row?.original}
            resetState={resetState}
            projectType={"tdp"}
          />
        )
      case "CP":
        return (
          <CompleteModal
            project={row?.original}
            resetState={resetState}
            projectType={"tdp"}
          />
        )
      case "AR":
        return (
          <EditStatusButton
            project={row?.original}
            resetState={resetState}
            projectType={"tdp"}
          />
        )
      case "CN":
        return (
          <EditStatusButton
            project={row?.original}
            resetState={resetState}
            projectType={"tdp"}
          />
        )
      default:
        return null
    }
  }

  const resetState = () => {
    getProjects()
  }

  const columns = [
    columnHelper.accessor("button", {
      cell: ({ row }) =>
        isAuthenticated && getModalComponent(statusFilter, row),
      header: () => <span></span>,
    }),
    // columnHelper.accessor("purpose", {
    //   cell: (info) => info.getValue(),
    //   header: () => <span>Purpose</span>,
    // }),
    columnHelper.accessor("quantity", {
      cell: (info) => info.getValue(),
      header: () => <span>Quantity</span>,
    }),
    columnHelper.accessor("customerName", {
      cell: (info) => info.getValue(),
      header: () => <span>Customer Name</span>,
    }),
    columnHelper.accessor("customerEmail", {
      cell: (info) => info.getValue(),
      header: () => <span>Customer Email</span>,
    }),
    columnHelper.accessor("date", {
      cell: (info) => info.getValue(),
      header: () => <span>Date</span>,
    }),
    columnHelper.accessor("buttons", {
      cell: ({ row }) => {
        return (
          isAuthenticated && (
            <Row>
              <Col>
                <NewProjectTDPModal
                  create={false}
                  project={row.original}
                  resetState={resetState}
                />
              </Col>
              <Col>
                <EditStatusButton
                  project={row.original}
                  resetState={resetState}
                  projectType={"tdp"}
                />
              </Col>
              <Col>
                <MoveToCancelledModal
                  project={row.original}
                  resetState={resetState}
                  projectType={"tdp"}
                />
              </Col>
            </Row>
          )
        )
      },
      header: () => <span></span>,
    }),
  ]

  useEffect(() => {
    getProjects()
    resetState()
  }, [])

  const getProjects = async () => {
    try {
      const result = await axios.get(PROJECT_TDP_URL)
      setProjects(result.data)
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  const filteredProjects = projects.filter((project) => {
    if (project.status === statusFilter) {
      return true
    } else {
      return false
    }
  })

  return (
    <Container className="main-container">
      <Table columns={columns} parentData={filteredProjects} paginate={true} />
    </Container>
  )
}

export default ProjectTDPList
