import React, { useState, useEffect, useRef } from "react"
import { Button, Form, FormGroup, Input, Label, Alert } from "reactstrap"
import api from "../../api"
import { GROUPS_URL } from "../../constants"

const NewGroupForm = ({ group, resetState, toggle }) => {
  const [pk, setPk] = useState(0)
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")
  const [balance, setBalance] = useState(0)
  const [operatingUnit, setOperatingUnit] = useState(null)
  const [accountCode, setAccountCode] = useState(null)
  const [classCode, setClassCode] = useState(null)
  const [department, setDepartment] = useState("")
  const [controllerEmail, setControllerEmail] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  const inputRef = useRef(null)

  useEffect(() => {
    if (group) {
      const {
        pk,
        name,
        description,
        balance,
        operatingUnit,
        accountCode,
        classCode,
        department,
        controllerEmail,
      } = group

      setPk(pk)
      setName(name)
      setDescription(description)
      setBalance(balance)
      setOperatingUnit(operatingUnit)
      setAccountCode(accountCode)
      setClassCode(classCode)
      setDepartment(department)
      setControllerEmail(controllerEmail)
    }
  }, [group])

  // Length validation checks
  const checkLengths = () => {
    if (operatingUnit.length > 8) {
      setErrorMessage("Operating Unit # cannot be longer than 8 characters")
      return false
    }
    if (accountCode && accountCode.length > 4) {
      setErrorMessage("Account Code cannot be longer than 4 digits")
      return false
    }
    return true
  }

  // Create and edit item request handlers
  const handleSubmit = async (e) => {
    e.preventDefault()

    // Field validation
    if (!name || !operatingUnit || !department || !controllerEmail) {
      setErrorMessage("Please fill out all required fields.")
      return
    }

    if (!checkLengths()) {
      return
    }

    const payload = {
      pk,
      name,
      description,
      balance,
      operatingUnit,
      accountCode,
      classCode,
      department,
      controllerEmail,
    }

    try {
      const url = group ? `${GROUPS_URL}${pk}/` : GROUPS_URL
      const method = group ? api.put : api.post

      await method(url, payload)
      resetState()
      toggle()
    } catch (error) {
      console.error("Error submitting form: ", error)
      setErrorMessage("Error creating/editing group. Please try again.")
    }
  }

  useEffect(() => {
    // Focus the input field when the component mounts
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  return (
    <Form onSubmit={handleSubmit}>
      {errorMessage && <Alert color="danger">{errorMessage}</Alert>}

      <FormGroup>
        <Label for="name">Group Name:</Label>
        <Input
          type="text"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          innerRef={inputRef}
        />
      </FormGroup>

      <FormGroup>
        <Label for="description">Description:</Label>
        <Input
          type="text"
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </FormGroup>

      <FormGroup>
        <Label for="balance">Balance:</Label>
        <Input
          type="number"
          name="balance"
          value={balance}
          onChange={(e) => setBalance(parseFloat(e.target.value) || 0)}
        />
      </FormGroup>

      <FormGroup>
        <Label for="operatingUnit">Operating Unit #:</Label>
        <Input
          type="text"
          name="operatingUnit"
          value={operatingUnit}
          onChange={(e) => setOperatingUnit(e.target.value)}
        />
      </FormGroup>

      <FormGroup>
        <Label for="accountCode">Account Code:</Label>
        <Input
          type="text"
          name="accountCode"
          value={accountCode}
          onChange={(e) => setAccountCode(e.target.value)}
        />
      </FormGroup>

      <FormGroup>
        <Label for="classCode">Class Code:</Label>
        <Input
          type="text"
          name="classCode"
          value={classCode}
          onChange={(e) => setClassCode(e.target.value)}
        />
      </FormGroup>

      <FormGroup>
        <Label for="department">Department:</Label>
        <Input
          type="text"
          name="department"
          value={department}
          onChange={(e) => setDepartment(e.target.value)}
        />
      </FormGroup>

      <FormGroup>
        <Label for="controllerEmail">Controller Email:</Label>
        <Input
          type="email"
          name="controllerEmail"
          value={controllerEmail}
          onChange={(e) => setControllerEmail(e.target.value)}
        />
      </FormGroup>

      <Button type="submit" color="primary">
        {group ? "Update" : "Create"} Group
      </Button>
    </Form>
  )
}

export default NewGroupForm
