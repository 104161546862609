import React, { useEffect, useState } from "react"
import { Button, ModalHeader, ModalBody, Spinner, Alert } from "reactstrap"
import { PDFViewer } from "@react-pdf/renderer"

import CheckoutForm from "./CheckoutForm"
import EmailReceiptButton from "../Receipts/EmailReceiptButton"
import ReceiptsPDF from "../Receipts/ReceiptsPDF"

const PersonalCheckout = ({
  purchaseSuccess,
  totalLoans,
  handlePurchaseButton,
  totalPrice,
  loading,
  purchaseLoading,
  cardNumber,
  data,
  toggle,
  handleCardDataSubmit,
  dataFetched,
  receipt,
  errorMessage,
}) => {
  return (
    <>
      <ModalHeader toggle={toggle}>Personal Checkout</ModalHeader>
      <ModalBody>
        {!cardNumber ? (
          <>
            <CheckoutForm handleCardDataSubmit={handleCardDataSubmit} />
          </>
        ) : dataFetched === false ? (
          <>
            <Alert color="danger">
              <h4 className="alert-heading">Error!</h4>
              <p>Invalid BYU ID. Please try again!</p>
            </Alert>
          </>
        ) : loading ? (
          <div className="text-center">
            <Spinner color="primary" />
            <p>Loading...</p>
          </div>
        ) : purchaseLoading ? (
          <div className="text-center">
            <Spinner color="primary" />
            <p>Processing purchase...</p>
          </div>
        ) : purchaseSuccess === "success" ? (
          <div className="text-center">
            <Alert color="success">
              <h4 className="alert-heading">Success!</h4>
              <p>Your purchase was successful!</p>
            </Alert>
            <EmailReceiptButton data={data} purchase={receipt} />
            <PDFViewer showToolbar={true} width={"100%"} height={600}>
              <ReceiptsPDF
                purchase={receipt}
                total={receipt.total}
                groupName={receipt.groupName}
              />
            </PDFViewer>
            <Button color="primary" onClick={toggle}>
              Close
            </Button>
          </div>
        ) : (
          <div>
            <h3>Card Information</h3>
            <p>Card Number: {cardNumber}</p>
            {data.name !== null ? (
              <>
                <p>Purchaser Name: {data.name}</p>
                <p>Net ID: {data.net_id}</p>
              </>
            ) : (
              <>
                <p>Purchaser Name: Campus Card</p>
                <p>Net ID: N/A</p>
              </>
            )}
            <p>Total Price: {totalPrice}</p>
            <p>Total Loans: {totalLoans}</p>
            <Button onClick={handlePurchaseButton}>Checkout</Button>
            <br />
            <br />
            {purchaseSuccess === "failed" && (
              <Alert color="danger">
                <h4 className="alert-heading">{errorMessage}</h4>
                <p>Error!</p>
              </Alert>
            )}
          </div>
        )}
      </ModalBody>
    </>
  )
}

export default PersonalCheckout
